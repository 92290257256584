@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Retro Gaming";
    font-style: normal;
    font-weight: normal;
    src: local("Retro Gaming"), url("./fonts/Retro Gaming.woff") format("woff");
  }
  
  @font-face {
    font-family: "04b-30";
    src: url("./fonts/04b-30.ttf.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.4);
  }
  
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #c12b2b;
  }
  
  html {
    overflow-y: overlay;
    overflow-x: hidden;
    background: black;
    scroll-behavior: smooth;
    height: 100%;
  }
  
  .animate__animated {
    --animate-duration: 0.2s;
  }
  
  .glass-effect {
    @apply relative after:absolute after:w-full after:h-full;
    @apply after:bg-size-200 after:transition-all after:duration-300 after:bg-no-repeat after:bg-pos-100 after:hover:bg-pos-0 after:opacity-90 ;
    @apply after:pointer-events-none;
    /* @apply hover:rotate-90; */
  }
  .glass-effect::after {
    background-image: linear-gradient(120deg, rgba(0,0,0,0) 49%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.5) 50%, rgba(0,0,0,0) 53%, rgba(0,0,0,0) 54%, rgba(255,255,255,0.6) 54.5%, rgba(255,255,255,0.6) 57%, rgba(0,0,0,0) 58%);
  }
  
  .text-shadow {
    text-shadow: rgba(0,0,0,0.4) 3px 5px 6px;
  }
  
  .depth {
    display: block;
    padding: 2px;
    color: rgb(54, 51, 51);
    font: bold Arial, sans-serif;
    position: relative;
  }
  
  .depth:before, .depth:after {
    content: attr(title);
    padding: 1px;
    color: rgba(255,255,255,.1);
    position: absolute;
  }
  
  .depth:before { top: 1px; left: 1px }
  .depth:after  { top: 2px; left: 2px }